<template>
  <div>
    <header-slot />

    <b-nav card-header pills class="m-0">

      <!-- Tab of New Clients -->
      <b-nav-item
        :to="{ name: 'administration-sales-made-new-client' }"
        :class="{ 'active bg-tab rounded-top': isNewClient }"
        :link-classes="linkClasses"
        >New Clients</b-nav-item
      >

      <!-- Tab of Add or Change Service -->
      <b-nav-item
        :to="{ name: 'administration-sales-made-add-change' }"
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Add/Change Service</b-nav-item
      >

      <!-- Tab of Annulled -->
      <b-nav-item
        :to="{ name: 'administration-sales-made-annuled' }"
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Recovered</b-nav-item
      >
    </b-nav>

    <b-card no-body class="border-top-primary border-3 border-table-radius">
      <router-view />
    </b-card>
  </div>
</template>

<script>
export default {
  computed: {
    isNewClient() {
      return this.$route.name === "administration-sales-made-new-client";
    },
    linkClasses() {
      if (this.isNewClient) return ["px-3", "bg-tab", "text-white"];
      return ["px-3", this.bgTabsNavs];
    },
  },
};
</script>

<style>
.bg-tab {
  background-color: #0090e7;
}
</style>